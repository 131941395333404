//useAuth.js

import { checkPermissionforUser } from "../utils/Home";


export const useAuth = () => {
    //getting token from local storage
    const user = localStorage.getItem('token')
    //checking whether token is preset or not
    if (user) {
        const decoded = decodeJWT(user)
        const exp = decoded.payload.exp
        if (window.location.pathname !== "/") {
            checkAppPeremission(window.location.pathname).then((res) => {
                if (res === false) {
                    window.location.href = "/"
                }
            })
        }

        const currentTime = new Date()
        if (exp < currentTime.getTime() / 1000) {
            return false
        } else {
            return true
        }
    } else {
        return false
    }
};

async function checkAppPeremission(path: string) {
    return await checkPermissionforUser(path)
}

function base64UrlDecode(base64Url: any) {
    // Base64-URL-sichere Kodierung in normale Base64 umwandeln
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    // Base64 dekodieren
    const decodedData = atob(base64);
    // In JSON parsen
    return JSON.parse(decodedData);
}

function decodeJWT(token: any) {
    const [header, payload, signature] = token.split('.');
    const decodedHeader = base64UrlDecode(header);
    const decodedPayload = base64UrlDecode(payload);

    return {
        header: decodedHeader,
        payload: decodedPayload,
        signature: signature
    };
}