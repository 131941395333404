import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Alert, CardActionArea } from '@mui/material';
import { App } from '../../lib/dataTypes/App';




export default function HomeCards(props: App) {
  return (
    <div className='m-3'>
      <Card sx={{ maxWidth: 345, height: 250 }} onClick={() => { window.location.href = props.url }}>
        <CardActionArea sx={{ height: 250 }}>
          {props.imageUrl !== "" && props.imageUrl !== null && 
          <CardMedia
            component="img"
            sx={{ height: 140, width: 345 }}
            image={require(`../../img/${props.imageUrl}`)}
            alt={props.name}
          />}
          {(props.imageUrl === "" || props.imageUrl == null) && 
          <CardMedia
            component="img"
            sx={{ height: 140, width: 345 }}
            image={require(`../../img/default.png`)}
            alt={props.name}
          />}
          <CardContent>
            {props.label !== "" && <Alert className='absolute  right-2 top-2' icon={false} severity="success">
              {props.label}
            </Alert>}
            <Typography gutterBottom variant="h5" component="div">
              {props.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {props.description}
            </Typography>
            <Typography variant="caption" display="block" color="text.secondary">
              Version: {props.version}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
}