
import { serverPath } from "../lib/Store";

export async function userHomeApp(){
    const response = await fetch(`${serverPath}api/v1/home`,
        {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        }
        );
        if (response.status === 200) {
            const data = await response.json();
            return data;
        }
        return [];
}
export async function userDrawerApps(){
    const response = await fetch(`${serverPath}api/v1/home/drawer`,
        {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        }
        );
        if (response.status === 200) {
            const data = await response.json();
            return data;
        }
        return [];
}


export async function checkPermissionforUser(path:String) {
    
    const response = await fetch(`${serverPath}api/v1/home/checkPermission`,
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(path)
    })
        ;
        
        if (response.status === 200) {
            return true;
        }
        return false;
    
}


