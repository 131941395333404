import AppBarHome from "../../components/AppBars/AppBar";
import HomeCards from "../../components/Cards/HomeCards";
import * as React from "react";
import { userHomeApp } from "../../utils/Home";
import { createApp } from "../../utils/Admin/App";
import { App } from "../../lib/dataTypes/App";
export class Home extends React.Component {

  state =  {
    apps: [] as App[] 
  }

  componentDidMount(): void {
      this.load()
  }

  load = async () => {
    const apps = await userHomeApp()
      this.setState({apps: apps})
      
  }

  createApp = async () => {
    const app:App = {
      name: "Test",
      description: "Test",
      version: "1.0",
      label: "Test",
      url: "Test",
      imageUrl: "Test"
    }
    createApp(app)
  }

  render() {
    return (
      <div>
        <AppBarHome title="CoupleThings" />
        <div className="flex flex-col justify-center items-center w-screen md:flex-row flex-wrap">
          {this.state.apps?.map(function (app: any)  {
            
            return (
              <HomeCards
              key={app.name}
                name={app.name}
                description={app.description}
                url={app.path}
                imageUrl={app.imageUrl}
                label={app.label}
                version={app.version}
              />
            );
          }, this)}
        </div>
      </div>
    );
  }
}
