import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PaymentsIcon from '@mui/icons-material/Payments';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { userDrawerApps } from '../../utils/Home';

// Beispiel für eine Funktion, die Daten aus einer Datenbank abruft
async function fetchDrawerList() {
    // Hier sollten Sie Ihre Logik zum Abrufen der Daten aus der Datenbank hinzufügen
    // Dies ist ein Beispiel für statische Daten
    return await userDrawerApps();
}

// Funktion zum Zuordnen von Icon-Namen zu Icon-Komponenten
const iconMap: { [key: string]: React.ReactElement } = {
    PaymentsIcon: <PaymentsIcon />,
    LocalFireDepartmentIcon: <LocalFireDepartmentIcon />,
    LocalMallIcon: <LocalMallIcon />,
    LocalActivityIcon: <LocalActivityIcon />,
    AdminPanelSettingsIcon: <AdminPanelSettingsIcon />
};

export function MenuDrawerContent() {
    const [drawerList, setDrawerList] = React.useState<{ path: string, icon: string, name: string }[]>([]);

    React.useEffect(() => {
        async function loadData() {
            const data = await fetchDrawerList();
            setDrawerList(data);
        }
        loadData();
    }, []);

    return (
        <div>
            {drawerList.map((item, index) => (
                <ListItem key={index}>
                    <ListItemButton href={item.path}>
                        <ListItemIcon>
                            {iconMap[item.icon]}
                        </ListItemIcon>
                        <ListItemText primary={item.name} />
                    </ListItemButton>
                </ListItem>
            ))}
        </div>
    );
}