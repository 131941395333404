import React from 'react';
import AppBarHome from '../../components/AppBars/AppBar';
import { Divider } from '@mui/material';
interface AdminProps {
    
}
 
interface AdminState {
    
}
 
class Admin extends React.Component<AdminProps, AdminState> {
    
    render() { 
        return ( 
            <div>
                <AppBarHome title="Admin" />

                <div className='flex flex-row min-h-dvh w-screen '>
                    <div className='w-60 p-10'>
                        test
                    </div>
                    <Divider orientation="vertical" flexItem />
                    <div>

                    </div>
                </div>
            </div>
         );
    }
}
 
export default Admin;