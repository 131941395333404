import { UUID } from "crypto";
import { BetCreteDTO } from "../../lib/dataTypes/Bets";
import { serverPath } from "../../lib/Store";

export async function getBets() {

    const response = await fetch(`${serverPath}api/v1/bet`,
        {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
    })
        ;
    if (response.status === 200) {
        return await response.json();
    }
}


export async function createBet(createBetDTO: BetCreteDTO) {
   
    const response =await fetch(`${serverPath}api/v1/bet`,
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(createBetDTO)
    })
        ;
    if (response.status === 200) {
        return response.status;
    }
    
}

export async function updatePaidStatus(id: UUID) {

    const response = await fetch(`${serverPath}api/v1`,
        {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            
    })
        ;
    if (response.status === 200) {
    }
    
}

export async function deleteApp(id: UUID) {

    const response = await fetch(`${serverPath}api/v1`,
        {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
    })
        ;
    if (response.status === 204) {
    }
    
}