import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import { ListItemButton } from "@mui/material";
import { MenuDrawerContent } from "./MenuDrawerContent";

export const DrawerList = (
  <Box sx={{ width: 250 }} role="presentation">
    <List>
      <ListItem>
        <ListItemButton href="/">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
      </ListItem>
    </List>
    <Divider />
    <MenuDrawerContent />
    
  </Box>
);
