import React from "react";
import AppBarHome from "../../components/AppBars/AppBar";
import HitCounterList from "./ListComponent";
import { Stack } from "@mui/material";
import AddHits from "../../components/dialogs/AddPenalty";
import { DatePicker } from "@mui/x-date-pickers";
import "dayjs/locale/de";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import HitReceiveConfirm from "../../components/dialogs/HitReceiveConfirm";
import Alert from "@mui/material/Alert";
import { HitCount } from "../../lib/dataTypes/HitCountApp";
import {
  getHitCount,
  getNotReceived,
  getPenalties,
} from "./HitCounterRequests";
import { Await } from "react-router-dom";
export class HitCounter extends React.Component {
  state = {
    hitcount: 0,
    receivedHitCount: 0,
    alertNotReveived: "flex",
    alertNotReceivedMessage: "",
    alertReceived: "none",
    penalties: [],
    date: dayjs().toISOString(),
    receivedButton: "flex",
  };
  componentDidMount(): void {
    this.load();
  }

  load = async () => {
    const hitcount: HitCount = await getHitCount(this.state.date);
    if (!hitcount.received && hitcount.number !== hitcount.receivedNumber) {
      this.setState({ alertReceived: "none" });
      this.setState({ receivedButton: "flex" });
    } else if (hitcount.received && (hitcount.number === 0 || hitcount.number === hitcount.receivedNumber)) {
      this.setState({ receivedButton: "none" });
      if(hitcount.number === 0){
        this.setState({ alertReceived: "none" });
      }
      else{
        this.setState({ alertReceived: "flex" });
      }
    }
  

    this.setState({ hitcount: hitcount.number });
    this.setState({ receivedHitCount: hitcount.receivedNumber });

    const penalties = await getPenalties(this.state.date);
    this.setState({ penalties: penalties });

    const data = await getNotReceived();
    if (data.length === 0) {
      this.setState({ alertNotReveived: "none" });
    } else {

      const dateString = data.map((item: any) => {
        const date = new Date(item.date);
        let dateString: string = "";
        dateString =
          dateString +
          date.getDate() +
          "." +
          (date.getMonth() + 1) +
          "." +
          date.getFullYear() +
          ", ";
        return dateString;
      });
      this.setState({ alertNotReceivedMessage: dateString });
    }
  };
  handleDateChange = async (date: any) => {
    this.setState({ date: date.toISOString() }, async () => {
      await this.load();
    });
  };
  render() {
    
    return (
      <div>
        <AppBarHome title="Hit Counter" />
        <Stack className="mt-5 flex justify-center items-center flex-col w-full" spacing={2}>
          <Alert
            sx={{ display: this.state.alertReceived }}
            className="w-1/2"
            variant="outlined"
            severity="success"
          >
            Sub hat die Strafe heute bereits erhalten!
          </Alert>
          <Alert
            sx={{ display: this.state.alertNotReveived }}
            className="w-1/2"
            variant="outlined"
            severity="warning"
          >
            Der Sub hat die Strafe am: {this.state.alertNotReceivedMessage} noch
            nicht erhalten!
          </Alert>
        </Stack>

        <div className="mt-5 w-full h-[60vh] flex justify-center items-center flex-col md:flex-row">
          <div className="w-auto flex flex-col justify-start items-center">
            <h1 className="text-6xl m-0">{this.state.receivedHitCount}/{this.state.hitcount}</h1>
            <div className="flex justify-center items-center flex-col m-5">
              <Stack spacing={2}>
                <AddHits onDataRecieved={this.load} date={this.state.date} />

                <HitReceiveConfirm
                  receivedButton={this.state.receivedButton}
                  onDataRecieved={this.load}
                  date={this.state.date}
                />

                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="de"
                >
                  <DatePicker
                    label="Datum"
                    defaultValue={dayjs()}
                    onChange={(newValue) => this.handleDateChange(newValue)}
                  />
                </LocalizationProvider>
              </Stack>
            </div>
          </div>

          <HitCounterList data={this.state.penalties} />
        </div>
      </div>
    );
  }
}
