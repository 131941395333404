
import * as React from 'react';
import { useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { Bet } from '../../lib/dataTypes/Bets';

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Name', flex: 1 },
  { field: 'winner', headerName: 'Gewinner', flex: 1 },
  { field: 'loser', headerName: 'Verlierer', flex: 1 },
  {
    field: 'paid',
    headerName: 'Bezahlt',
    type: 'boolean',
    flex: 0.5,
  },

];

const paginationModel = { page: 0, pageSize: 25 };

export default function BetTable(props:{bets:Bet[]}) {
  const [rows, setRows] = React.useState<Bet[]>([]);
  useEffect(() => {
    
    setRows(props.bets);
  }, [props.bets]);
  return (
    <Paper sx={{  width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{ pagination: { paginationModel } }}
        pageSizeOptions={[5, 10, 25]}
        sx={{ border: 0 }}
      />
    </Paper>
  );
}


