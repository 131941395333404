
import { App } from "../../lib/dataTypes/App";

export async function createApp(createApp: App) {
    const response = await fetch(`http://localhost:8080/api/v1/admin/app`,
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(createApp)
    })
        ;

        if (response.status === 201) {
        }
    
}