import AppBarHome from "../../components/AppBars/AppBar";
import * as React from "react";
import BetTable from "./Bet_Table";
import { Button, Checkbox, FormControlLabel, MenuItem, TextField } from "@mui/material";
import { Bet, BetCreteDTO } from "../../lib/dataTypes/Bets";
import { createBet, getBets } from "../../utils/bets/Bets";
export class BettingOffice extends React.Component {
  state =  {
    name: "",
    winner: "",
    loser: "",
    paid: false,
    bets: [] as Bet[]
  }
  constructor(props: any) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.loadBets();
  }

  loadBets = async () => {
    const betRespone =  await getBets();
    this.setState({bets: betRespone});
    console.log(betRespone);
  }

  onSubmit = async () => {
    
    const data:BetCreteDTO = {
      name: this.state.name,
      winner: this.state.winner,
      paid: this.state.paid
    }

    const response = await createBet(data);
    if(response === 200){
      this.loadBets();
    }
  }

  handleNameChange = (event:any) => {
    this.setState({name: event.target.value});
  };

  handleWinnerChange = (event:any) => {
    this.setState({winner: event.target.value});
  };
  handlePaidChange = (event:any) => {
    if(event.target.value === "on"){
      this.setState({paid: true});
      
    }
    else{
      this.setState({paid: false});
    }
  };

  render() {
    const currencies = [
      {
        value: 'mabu2807',
        label: 'Marcel',
      },
      {
        value: 'Nora',
        label: 'Nora',
      },
    ];
    return (
      <div>
        <AppBarHome title="Wettbüro" />
        <div className="flex  flex-col mt-2">
        <div className="md:border-2 md:mx-2 bg-neutral-800 flex md:flex-row flex-col h-80 md:h-auto justify-around items-center ">
          <TextField id="outlined-basic" label="Wette" variant="outlined" onInput={this.handleNameChange} />
          <TextField
            id="filled-select-winner"
            select
            label="Gewinner"
            helperText="Wähle den Gewinner der Wette aus"
            variant="filled"
            onChange={this.handleWinnerChange}
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <FormControlLabel labelPlacement="start" control={<Checkbox onChange={this.handlePaidChange} />} label="Bezahlt?" />
          <div>
            <Button onClick={this.onSubmit} variant="contained">Anlegen</Button>
          </div>
        </div>
        <div className="my-4">
          <BetTable bets={this.state.bets}/>
        </div>
        </div>
      </div>
    );
  }
}
